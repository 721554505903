var processInclude = require('base/util');
// This list should include any JS files that check for specific nodes in DOM that may be added to belowFold region
var coreFiles = {
    productTile: require('core/components/productTile'),
    video: require('core/components/video'),
    slider: require('./components/slider'),
    pageDesigner: require('core/pageDesigner')
}

function lazyLoadBelowFoldComponents() {
    const belowFoldComponents = $("#belowFoldRegion");
    const loadComponent = (component) => {
      const url = component.data("url");
      return $.ajax({
        url,
        method: "GET"
      }).then(response => {
        component.html(response);
        // Initialize any components that were just loaded
        $.each(coreFiles, (key, value) => {
          if (key === "slider") {
            coreFiles.slider.initializeSliders(component);
          } else {
            processInclude(value);
          }
        });
      }).catch(err => {
        console.error(`Error loading component: ${err}`);
      });
    };
  
    // Use IntersectionObserver API to load components when they enter the viewport
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadComponent($(entry.target));
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: document,
      rootMargin: '0px 0px 1500px 0px',
    });
    belowFoldComponents.each((index, component) => {
      observer.observe(component);
    });
  
    // Load components that are already visible on initial page load
    Promise.all(belowFoldComponents.toArray().map(component => {
      if ($(component).offset().top <= $(window).scrollTop() + $(window).height()) {
        return loadComponent($(component));
      }
    })).then(() => {
      console.log("All components loaded");
    });
  }
  
  lazyLoadBelowFoldComponents();
  
$(document).ready(function() {
    // Lazy Load Initialization
    $('.lazy').Lazy();
});
